/** @jsx jsx */
import { jsx } from 'theme-ui';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import DateView, { DateType } from './views/Date';
import EventView from './views/Event';
import HomeView from './views/Home';
import PrivacyView from './views/Privacy';

import './Main.css';

function App() {
    return (
        <div>
            <Router>
                <Switch>
                    <Route exact path="/:year(-?[0-9]{1,5})/:month([0-9]{1,2})/:day([0-9]{1,2})">
                        <DateView dateType={DateType.Day} />
                    </Route>
                    <Route exact path="/:year(-?[0-9]{1,5})/:month([0-9]{1,2})">
                        <DateView dateType={DateType.Month} />
                    </Route>
                    <Route exact path="/search">
                        <DateView dateType={DateType.NA} />
                    </Route>
                    <Route exact path="/e/:eventid([0-9]+)">
                        <EventView />
                    </Route>
                    <Route path="/privacy">
                        <PrivacyView />
                    </Route>
                    <Route path="/">
                        <HomeView />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
