/** @jsx jsx */
import { jsx } from 'theme-ui';
import ContentWithAside from './ContentWithAside';
import '../Fonts.css';
import { faExternalLinkAlt, faThumbtack, faCalendarAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateToStr, DateToUrl } from '../Common';
import '../Main.css';
import { Skeleton } from './Skeleton';

export type EventItem = {
    date: string;
    year: number;
    month: number;
    day: number;
    title: string;
    event: string;
    accuracy: number;
    exact: boolean;
    context: string;
    location: string;
    wikiid: number;
    eid: number;
    position: number;
    contextPos: number;
    image: string;
};

type EventProps = {
    event?: EventItem;
    showDate: boolean;
    detail: boolean;
    headline: boolean;
};

export function Event({ event, showDate, detail, headline }: EventProps) {
    function getParagraphs(str: string, keyword: string, highlightPos: number, prefix: string, suffix: string) {
        const paras = str.split('\n');
        while (paras.length > 0 && paras[paras.length - 1].length === 0) {
            paras.pop();
        }

        let currPos = 0;
        let highlighted = false;
        return paras.map((i, key) => {
            currPos += i.length + 1;
            if (i.length === 0) {
                return null;
            }
            if (currPos <= highlightPos || highlighted) {
                return <p key={key}>{(key === 0 ? prefix : '') + i + (key === paras.length - 1 ? suffix : '')}</p>;
            } else {
                const split = highlightPos + i.length - currPos;
                highlighted = true;
                return (
                    <p key={key}>
                        {key === 0 ? prefix + i.substring(0, split) : i.substring(0, split)}
                        <span
                            sx={{
                                backgroundColor: 'var(--color-yellow)'
                            }}
                        >
                            {i.substring(split, split + keyword.length + 1)}
                        </span>
                        {key === paras.length - 1
                            ? i.substring(split + keyword.length + 1) + suffix
                            : i.substring(split + keyword.length + 1)}
                    </p>
                );
            }
        });
    }

    function getHeadline(paragraph: string) {
        const spans = paragraph.split('<b>');
        return spans.map((span, key) => {
            const parts = span.split(/<\/b>(.*)/);
            if (parts.length === 1) {
                return <span key={key}>{parts[0]}</span>;
            } else {
                return (
                    <span key={key}>
                        <span
                            sx={{
                                background: 'var(--color-yellow)'
                            }}
                        >
                            {parts[0]}
                        </span>
                        <span>{parts[1]}</span>
                    </span>
                );
            }
        });
    }

    function getParagraphsHeadline(str: string, pos: number, context: number) {
        let prefix = '';
        let suffix = '';
        if (pos > context) {
            prefix = '...';
        }
        if (pos + context < str.length) {
            suffix = '...';
        }
        const paras = (prefix + str.substring(pos - context, pos + context) + suffix).split('\n');
        while (paras.length > 0 && paras[paras.length - 1].length === 0) {
            paras.pop();
        }

        return paras.map((i, key) => {
            return <p key={key}>{getHeadline(i)}</p>;
        });
    }

    function getMiddleParagraphs(str: string, keyword: string, pos: number, context: number) {
        let prefix = '';
        let suffix = '';
        if (pos > context) {
            prefix = '...';
        }
        if (pos + keyword.length + context < str.length) {
            suffix = '...';
        }
        return getParagraphs(
            str.substring(pos - context, pos + context),
            keyword,
            pos > context ? context : pos,
            prefix,
            suffix
        );
    }

    function dateToStr(year: number, month: number, day: number, exact: boolean): string {
        const { dateStr } = DateToStr(year.toString(), month.toString(), day.toString(), exact);
        return dateStr;
    }

    function dateToUrl(year: number, month: number, day: number, exact: boolean): string {
        if (exact) {
            const d = new Date(`${year}/${month}/${day}`);
            return DateToUrl(d, exact);
        } else {
            const d = new Date(`${year}/${month}/01`);
            return DateToUrl(d, exact);
        }
    }

    return (
        <ContentWithAside
            styles={{
                marginTop: ['100px', '100px', '100px', '50px']
            }}
            main={
                <div
                    sx={{
                        marginLeft: ['20px', '40px', '40px', '40px'],
                        marginRight: ['20px', '40px', '40px', '10px'],
                        fontSize: ['16px', '18px', '24px', '24px']
                    }}
                >
                    {detail ? (
                        <div
                            sx={{
                                textAlign: ['justify', 'justify', 'justify', 'justify'],
                                hyphens: ['auto', 'auto', 'auto', 'auto']
                            }}
                        >
                            {event ? (
                                getParagraphs(
                                    event.context,
                                    event.date,
                                    event.contextPos,
                                    event.position !== event.contextPos ? '...' : '',
                                    event.context.slice(event.contextPos).split(' ').length > 256 ? '...' : ''
                                )
                            ) : (
                                <Skeleton
                                    repeat={100}
                                    globalStyles={{}}
                                    styles={{
                                        height: ['12px', '16px', '16px', '16px'],
                                        background: 'var(--color-lightgray)',
                                        borderRadius: ['6px', '8px', '8px', '8px']
                                    }}
                                />
                            )}
                        </div>
                    ) : (
                        <div>
                            <div
                                sx={{
                                    fontFamily: "'Oswald', sans-serif",
                                    fontWeight: 'bold'
                                }}
                            >
                                {event ? (
                                    <a href={'/e/' + event.eid}>
                                        "{event.event}"{' '}
                                        <span
                                            sx={{
                                                fontWeight: 'normal',
                                                textDecoration: 'underline',
                                                fontSize: ['14px', '16px']
                                            }}
                                        >
                                            [More]
                                        </span>
                                    </a>
                                ) : (
                                    <Skeleton
                                        repeat={1}
                                        globalStyles={{}}
                                        styles={{
                                            height: ['20px', '30px', '30px', '30px'],
                                            background: 'var(--color-gray)',
                                            borderRadius: ['10px', '15px', '15px', '15px']
                                        }}
                                    />
                                )}
                            </div>
                            <div
                                sx={{
                                    textAlign: ['justify', 'justify', 'justify', 'justify'],
                                    hyphens: ['auto', 'auto', 'auto', 'auto'],
                                    minHeight: [null, null, null, '350px']
                                }}
                            >
                                {event ? (
                                    headline ? (
                                        getParagraphsHeadline(event.context, event.contextPos, 300)
                                    ) : (
                                        getMiddleParagraphs(event.context, event.date, event.contextPos, 300)
                                    )
                                ) : (
                                    <Skeleton
                                        repeat={8}
                                        globalStyles={{}}
                                        styles={{
                                            height: ['12px', '16px', '16px', '16px'],
                                            background: 'var(--color-lightgray)',
                                            borderRadius: ['6px', '8px', '8px', '8px']
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            }
            aside={
                <div
                    sx={{
                        marginRight: ['auto', 'auto', 'auto', '40px'],
                        marginBottom: '20px'
                    }}
                >
                    <div
                        sx={{
                            marginBottom: ['10px', '10px', '10px', '40px'],
                            fontFamily: "'Oswald', sans-serif",
                            fontWeight: 'bold'
                        }}
                    >
                        {event ? (
                            <div>
                                {event.title}
                                <a
                                    href={'https://en.wikipedia.org/wiki?curid=' + event.wikiid}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Open on Wikipedia"
                                    sx={{
                                        marginLeft: '10px',
                                        fontSize: '14px',
                                        position: ['absolute', 'absolute', 'absolute', 'static']
                                    }}
                                >
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                            </div>
                        ) : (
                            <Skeleton
                                repeat={1}
                                globalStyles={{}}
                                styles={{
                                    height: ['20px', '30px', '30px', '30px'],
                                    background: 'var(--color-gray)',
                                    borderRadius: ['10px', '15px', '15px', '15px']
                                }}
                            />
                        )}
                    </div>
                    {event && event.image ? (
                        <div>
                            <img
                                sx={{
                                    padding: '2px',
                                    border: [
                                        '1px solid var(--color-blue)',
                                        '1px solid var(--color-blue)',
                                        '1px solid var(--color-blue)',
                                        '1px solid var(--color-white)'
                                    ],
                                    maxWidth: '100px',
                                    maxHeight: '150px'
                                }}
                                alt={event.image}
                                src={'https://en.wikipedia.org/wiki/Special:FilePath/File:' + event.image}
                            />
                        </div>
                    ) : null}
                    {event ? (
                        showDate ? (
                            <div
                                sx={{
                                    display: ['inline-block', 'inline-block', 'inline-block', 'block'],
                                    margin: ['0px 10px', '0px 10px', '0px 10px', '10px'],
                                    fontFamily: "'Oswald', sans-serif",
                                    fontSize: ['14px', '16px']
                                }}
                            >
                                <a href={dateToUrl(event.year, event.month, event.day, event.exact)}>
                                    {dateToStr(event.year, event.month, event.day, event.exact) + ' '}
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </a>
                            </div>
                        ) : null
                    ) : (
                        <Skeleton
                            repeat={1}
                            globalStyles={{
                                display: ['inline-block', 'inline-block', 'inline-block', 'block']
                            }}
                            styles={{
                                height: '12px',
                                background: 'var(--color-gray)',
                                borderRadius: '6px'
                            }}
                        />
                    )}
                    {event ? (
                        event.location ? (
                            <div
                                sx={{
                                    display: ['inline-block', 'inline-block', 'inline-block', 'block'],
                                    margin: ['0px 10px', '0px 10px', '0px 10px', '10px'],
                                    fontFamily: "'Oswald', sans-serif",
                                    fontSize: ['14px', '16px']
                                }}
                            >
                                <a href={'/search?q=' + encodeURI(event.location)}>
                                    {event.location + ' '}
                                    <FontAwesomeIcon icon={faThumbtack} />
                                </a>
                            </div>
                        ) : null
                    ) : (
                        <Skeleton
                            repeat={1}
                            globalStyles={{
                                display: ['inline-block', 'inline-block', 'inline-block', 'block']
                            }}
                            styles={{
                                height: '12px',
                                background: 'var(--color-gray)',
                                borderRadius: '6px'
                            }}
                        />
                    )}
                    {event ? (
                        event.location ? (
                            <div
                                sx={{
                                    display: ['inline-block', 'inline-block', 'inline-block', 'block'],
                                    margin: ['0px 10px', '0px 10px', '0px 10px', '10px'],
                                    fontFamily: "'Oswald', sans-serif",
                                    fontSize: ['14px', '16px']
                                }}
                            >
                                {detail ? (
                                    <a href="#closeby">
                                        {'Browse closeby events '}
                                        <FontAwesomeIcon icon={faCaretDown} />
                                    </a>
                                ) : (
                                    <a href={'/e/' + event.eid + '?closeby=1'}>Browse closeby events</a>
                                )}
                            </div>
                        ) : null
                    ) : (
                        <Skeleton
                            repeat={1}
                            globalStyles={{
                                display: ['inline-block', 'inline-block', 'inline-block', 'block']
                            }}
                            styles={{
                                height: '12px',
                                background: 'var(--color-gray)',
                                borderRadius: '6px'
                            }}
                        />
                    )}
                    {event ? (
                        <div
                            sx={{
                                display: ['inline-block', 'inline-block', 'inline-block', 'block'],
                                margin: ['0px 10px', '0px 10px', '0px 10px', '10px'],
                                fontFamily: "'Oswald', sans-serif",
                                fontSize: ['14px', '16px']
                            }}
                        >
                            <a
                                href={
                                    'mailto:report@whataday.info?subject=Error on "' +
                                    event.title +
                                    '" (' +
                                    event.eid +
                                    ')'
                                }
                            >
                                Report error
                            </a>
                        </div>
                    ) : (
                        <Skeleton
                            repeat={1}
                            globalStyles={{
                                display: ['inline-block', 'inline-block', 'inline-block', 'block']
                            }}
                            styles={{
                                height: '12px',
                                background: 'var(--color-gray)',
                                borderRadius: '6px'
                            }}
                        />
                    )}
                </div>
            }
        />
    );
}
