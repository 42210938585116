/** @jsx jsx */
import { jsx } from 'theme-ui';

import Container from '../components/Container';
import Header from '../components/Header';
import Separator from '../components/Separator';
import Footer from '../components/Footer';
import ContentWithAside from '../components/ContentWithAside';

function PrivacyView() {
    return (
        <Container
            content={
                <div>
                    <Header
                        content={
                            <span
                                sx={{
                                    letterSpacing: '1px'
                                }}
                            >
                                EVERYDAY IS EVENTFUL
                            </span>
                        }
                        showSearch={false}
                    />
                    <Separator content={'Privacy Policy'} />
                    <ContentWithAside
                        styles={{}}
                        main={
                            <div
                                sx={{
                                    marginLeft: '40px',
                                    marginRight: ['40px', '40px', '40px', '10px']
                                }}
                            >
                                <h3>Personal identification information</h3>

                                <p>
                                    We collect email addresses of registered Users and any other information voluntarily
                                    entered into forms on the Site. We also collect registered Users' account creation
                                    dates and the number of times a User has signed in. None of this information is sold
                                    or provided to third parties, except to provide the products and services you've
                                    requested, with your permission, or as required by law.
                                </p>

                                <h3>Non-personal identification information</h3>

                                <p>
                                    We may collect non-personal identification information about Users whenever they
                                    interact with the Site, This may include: the browser name, the type of computer,
                                    the operating system, and other similar information.
                                </p>

                                <h3>Web browser cookies</h3>

                                <p>
                                    The Site may use "cookies" to enhance User experience. Users may choose to set their
                                    web browser to refuse cookies, or to indicate when cookies are being sent. Note that
                                    this may cause some parts of the Site to function improperly.
                                </p>

                                <h3>How we use collected information</h3>

                                <p>
                                    Nerf Software LLC collects and uses Users' personal information for the following
                                    purposes:
                                </p>
                                <ul>
                                    <li>
                                        To personalize user experience: to understand how our Users as a group use the
                                        Site.
                                    </li>
                                    <li>To improve our Site.</li>
                                    <li>To improve customer service.</li>
                                    <li>
                                        To process transactions: We may use the information Users provide about
                                        themselves when placing an order only to provide service to that order. We do
                                        not share this information with outside parties except to the extent necessary
                                        to provide the service.
                                    </li>
                                    <li>
                                        To send periodic emails: The email address Users provide for order processing
                                        will be used to send them information and updates pertaining to their order. It
                                        may also be used to respond to their inquiries, and/or other requests or
                                        questions. The User may also receive company news, updates, related product or
                                        service information, etc. If at any time the User would like to unsubscribe from
                                        receiving future emails, we include detailed unsubscribe instructions at the
                                        bottom of each email.
                                    </li>
                                    <li>To prevent fraud.</li>
                                </ul>

                                <h3>Where we send your data</h3>

                                <ul>
                                    <li>Billing providers. These let us charge you for the services we provide.</li>
                                    <li>
                                        Financial metrics aggregators, which let us make informed decisions about the
                                        finances of our company. Services that maintain our website. Your information
                                        may appear in error-tracking software if you encounter an error with our
                                        website. This is used to notify you of any technical problems that may affect
                                        your account.
                                    </li>
                                    <li>
                                        Non-personal identification information may be sent to analytics software. No
                                        personal data or data that could uniquely identify a User will be sent.
                                    </li>
                                </ul>

                                <h3>GDPR legal basis for collecting your information</h3>

                                <p>
                                    We collect the minimum amount of information needed to provide the products and
                                    services you've requested. How we collect and use your data is covered by our
                                    legitimate interest.
                                </p>

                                <h3>Your rights under GDPR</h3>

                                <p>You may:</p>
                                <ul>
                                    <li>Be informed whether we store your data.</li>
                                    <li>
                                        Be granted access to your data (with some stipulations – see GDPR Article 15 for
                                        the precise terms. Note that we store so little of your data that it may require
                                        more information to authenticate your request than information we actually
                                        hold).
                                    </li>
                                    <li>
                                        Correct any errors in our records – for instance, update your email address.
                                    </li>
                                    <li>Request that your records be erased.</li>
                                    <li>
                                        Restrict our processing of your data. This means that we will continue to store
                                        your data, but will not use it.
                                    </li>
                                    <li>Object to our use of your data.</li>
                                    <li>
                                        Request the data we hold be provided to you or to another service. See GDPR
                                        Article 20 for the precise terms.
                                    </li>
                                    <li>Not be subject to decisions based entirely on automated processing.</li>
                                </ul>
                                <p>
                                    Exercising some of these rights might interfere with our ability to provide you with
                                    our services. For instance, if you request your records be erased, information about
                                    your purchase history will also be removed.
                                </p>
                                <p>To exercise any of these rights, please write to support@nerfsoftware.com.</p>

                                <h3>How we protect your information</h3>

                                <p>
                                    We adopt appropriate data collection, storage and processing practices and security
                                    measures to protect against unauthorized access, alteration, disclosure or
                                    destruction of your personal information, username, password, transaction
                                    information and data stored on our Site.
                                </p>
                                <p>
                                    Sensitive and private data exchange between the Site and its Users happens over an
                                    SSL secured communication channel and is encrypted and protected with digital
                                    signatures.
                                </p>

                                <h3>Sharing your personal information</h3>

                                <p>
                                    We do not sell, trade, or rent Users' personal identification information to others.
                                    We may share generic aggregated demographic information not linked to any personal
                                    identification information regarding visitors and users with our business partners
                                    and trusted affiliates for the purposes outlined above.
                                </p>

                                <h3>Compliance with children's online privacy protection act</h3>

                                <p>
                                    Protecting the privacy of the very young is especially important. For that reason,
                                    no part of our website is structured to attract anyone under 13.
                                </p>

                                <h3>Changes to this privacy policy</h3>

                                <p>
                                    Nerf Software LLC has the discretion to update this privacy policy at any time. When
                                    we do, we will revise the updated date at the bottom of this page.
                                </p>

                                <h3>Contacting us</h3>

                                <p>Questions about this policy can be sent to support@nerfsoftware.com.</p>
                                <p>This document was last updated on May 11, 2020.</p>
                            </div>
                        }
                        aside={null}
                    />
                    <Footer />
                </div>
            }
        />
    );
}

export default PrivacyView;
