/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Container from '../components/Container';
import Header from '../components/Header';
import { EventItem, Event } from '../components/Event';
import Button from '../components/Button';
import Footer from '../components/Footer';
import Pagination from '../components/Pagination';
import { Fetch, DateToUrl, DateToStr } from '../Common';
import Separator from '../components/Separator';

import { faCaretLeft, faCaretRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum DateType {
    Day = 1,
    Month,
    NA
}

type DateViewProps = {
    dateType: DateType;
};

function DateView({ dateType }: DateViewProps) {
    const perPage = 20;

    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const [events, setEvents] = useState<(EventItem | undefined)[]>(new Array(perPage).fill(undefined));
    const [headerStr, setHeaderStr] = useState<string>('');
    const { year, month, day } = useParams();
    const [query, setQuery] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    function prev(year: number, month: number, day: number) {
        let d = new Date(`${year}/${month}/${day ? day : 1}`);
        let exact = true;
        if (day) {
            d.setDate(d.getDate() - 1);
        } else {
            d.setMonth(d.getMonth() - 1);
            exact = false;
        }
        window.location.href = DateToUrl(d, exact);
    }

    function next(year: number, month: number, day: number) {
        let d = new Date(`${year}/${month}/${day ? day : 1}`);
        let exact = true;
        if (day) {
            d.setDate(d.getDate() + 1);
        } else {
            d.setMonth(d.getMonth() + 1);
            exact = false;
        }
        window.location.href = DateToUrl(d, exact);
    }

    useEffect(() => {
        if (dateType !== DateType.NA) {
            const { dateStr, valid } = DateToStr(year, month, day, dateType === DateType.Day);
            if (!valid) {
                setHeaderStr('No Such Date!');
                return;
            }

            setHeaderStr(dateStr);
            document.title = dateStr + ', what happened? | Whataday';
        }

        const queryString = require('query-string');
        const params = queryString.parse(window.location.search);
        const page = params['page'];
        if (page) {
            setPage(Number(page));
        }
        const encodedQuery = params['q'];
        let decodedQuery = '';
        if (encodedQuery) {
            decodedQuery = decodeURIComponent(encodedQuery);
            document.title = 'Results for ' + decodedQuery + ' | Whataday';
        }
        setQuery(decodedQuery);
        let url = 'https://api.whataday.info/date?limit=' + perPage + '&page=' + (page ? page : 1);
        if (dateType !== DateType.NA) {
            url += '&year=' + year + '&month=' + month;
            if (dateType === DateType.Day) {
                url += '&day=' + day;
            }
        }

        if (decodedQuery === '') {
            if (dateType === DateType.NA) {
                return;
            }
        } else {
            url += '&query=' + encodedQuery;
        }

        setEvents(new Array(perPage).fill(undefined));
        Fetch<{ count: number; items: EventItem[] }>(url)
            .then(({ count, items }) => {
                setCount(count);
                setEvents(items);
            })
            .catch(() => setError(true));
    }, [year, month, day, dateType]);

    return (
        <Container
            content={
                <div>
                    <Header content={dateType === DateType.NA ? 'EVERYDAY IS EVENTFUL' : headerStr} showSearch={true} />
                    {error ? (
                        <Separator content={'Error loading page'} />
                    ) : (
                        <div>
                            {query !== '' ? (
                                <Separator
                                    content={
                                        <span>
                                            <FontAwesomeIcon icon={faSearch} />
                                            {'"' + query + '"'}
                                        </span>
                                    }
                                />
                            ) : dateType === DateType.NA ? (
                                <Separator content={'What do you want to search?'} />
                            ) : null}
                            {query === '' ? (
                                <div
                                    sx={{
                                        textAlign: ['center', 'left', 'left', 'left'],
                                        paddingLeft: ['0px', '40px', '40px', '40px']
                                    }}
                                >
                                    <Button
                                        text={
                                            <div>
                                                <FontAwesomeIcon icon={faCaretLeft} />
                                                {dateType === DateType.Day ? ' Prev Day' : ' Prev Month'}
                                            </div>
                                        }
                                        onClick={() => prev(year, month, day)}
                                        styles={{}}
                                    />
                                    <Button
                                        text={
                                            <div>
                                                {dateType === DateType.Day ? 'Next Day ' : 'Next Month '}
                                                <FontAwesomeIcon icon={faCaretRight} />
                                            </div>
                                        }
                                        onClick={() => next(year, month, day)}
                                        styles={{}}
                                    />
                                </div>
                            ) : null}
                            {events.length === 0 ? (
                                <div
                                    sx={{
                                        textAlign: ['center', 'left', 'left', 'left'],
                                        paddingLeft: ['0px', '40px', '40px', '40px'],
                                        marginTop: '20px'
                                    }}
                                >
                                    No results found
                                </div>
                            ) : (
                                events.map((item, index) => (
                                    <Event
                                        key={index}
                                        event={item}
                                        showDate={dateType !== DateType.Day}
                                        detail={false}
                                        headline={query !== ''}
                                    />
                                ))
                            )}
                            <Pagination perPage={perPage} total={count} currPage={page} />
                        </div>
                    )}
                    <Footer />
                </div>
            }
        />
    );
}

export default DateView;
