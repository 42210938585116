/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';

type ContentWithAsideProps = {
    main: React.ReactNode;
    aside: React.ReactNode;
    styles: object;
};

function ContentWithAside({ main, aside, styles }: ContentWithAsideProps) {
    return (
        <div sx={{ position: 'relative', width: ['auto', 'auto', 'auto', '716px'], ...styles }}>
            <aside
                sx={{
                    position: ['static', 'static', 'static', 'absolute'],
                    width: '100%',
                    height: ['auto', 'auto', 'auto', '100%'],
                    right: ['auto', 'auto', 'auto', '716px'],
                    color: ['var(--color-black)', 'var(--color-black)', 'var(--color-black)', 'var(--color-white)'],
                    overflowY: [null, null, null, 'hidden'],
                    overflowX: 'hidden'
                }}
            >
                <div
                    sx={{
                        float: ['none', 'none', 'none', 'right'],
                        display: ['block', 'block', 'block', 'inline-block'],
                        textAlign: ['center', 'center', 'center', 'right']
                    }}
                >
                    {aside}
                </div>
            </aside>
            <div>{main}</div>
        </div>
    );
}

export default ContentWithAside;
