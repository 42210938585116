/** @jsx jsx */
import { jsx } from 'theme-ui';
import ContentWithAside from '../components/ContentWithAside';

type SeparatorProps = {
    content: React.ReactNode;
};

function Separator({ content }: SeparatorProps) {
    return (
        <ContentWithAside
            styles={{
                marginTop: ['40px', '40px', '40px', '150px'],
                fontSize: ['30px', '50px'],
                fontWeight: 'normal',
                fontFamily: "'Anton', sans-serif"
            }}
            main={
                <div
                    sx={{
                        borderBottom: '3px solid var(--color-blue)'
                    }}
                >
                    <div
                        sx={{
                            marginLeft: ['20px', '40px', '40px', '40px'],
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {content}
                    </div>
                </div>
            }
            aside={
                <div
                    sx={{
                        borderBottom: '3px solid var(--color-white)',
                        width: '50px'
                    }}
                >
                    &nbsp;
                </div>
            }
        ></ContentWithAside>
    );
}

export default Separator;
