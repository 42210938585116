/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Separator from '../components/Separator';
import Container from '../components/Container';
import Header from '../components/Header';
import { EventItem, Event } from '../components/Event';
import Footer from '../components/Footer';
import Pagination from '../components/Pagination';
import { Fetch } from '../Common';
import Toggle from '../components/Toggle';

import { faClock, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function EventView() {
    const perPage = 10;

    const [page, setPage] = useState<number>(1);
    const [spacetime, setSpacetime] = useState<number>(0);
    const { eventid } = useParams();
    const [event, setEvent] = useState<EventItem>();
    const [closeby, setCloseby] = useState<(EventItem | undefined)[]>(new Array(perPage).fill(undefined));
    const [count, setCount] = useState<number>(0);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        Fetch<EventItem>('https://api.whataday.info/event?eid=' + eventid)
            .then(event => {
                setEvent(event);
                document.title = event.event + ' | Whataday';
            })
            .catch(() => setError(true));
    }, [eventid]);

    useEffect(() => {
        const queryString = require('query-string');
        const params = queryString.parse(window.location.search);
        const page = Number(params['page']);
        if (page) {
            setPage(page);
        }
        setCloseby(new Array(perPage).fill(undefined));
        Fetch<{ count: number; items: EventItem[] }>(
            'https://api.whataday.info/closeby?limit=' +
                perPage +
                '&eid=' +
                eventid +
                '&spacetime=' +
                (spacetime ? spacetime : 0) +
                '&page=' +
                (page ? page : 1)
        )
            .then(({ count, items }) => {
                setCount(count);
                setCloseby(items);
                if (Number(params['closeby']) === 1) {
                    document.getElementById('closeby')?.scrollIntoView();
                }
            })
            .catch(() => setError(true));
    }, [eventid, spacetime]);

    return (
        <Container
            content={
                <div>
                    <Header content={event ? event.event : <span>&zwnj;</span>} showSearch={true} />
                    {error ? (
                        <Separator content={'Error loading page'} />
                    ) : (
                        <div>
                            <div>
                                <Event event={event} showDate={true} detail={true} headline={false} />
                            </div>
                            <div id="closeby">
                                {event ? (
                                    event.location ? (
                                        <div>
                                            <Separator content={'Closeby Events'} />
                                            {spacetime === 0 ? (
                                                <Toggle
                                                    text={
                                                        <div>
                                                            <FontAwesomeIcon icon={faClock} /> Closer in time
                                                        </div>
                                                    }
                                                    styles={{
                                                        marginLeft: ['20px', '40px', '40px', '40px']
                                                    }}
                                                />
                                            ) : (
                                                <Toggle
                                                    text={
                                                        <div>
                                                            <FontAwesomeIcon icon={faClock} /> Closer in time
                                                        </div>
                                                    }
                                                    onClick={() => setSpacetime(0)}
                                                    styles={{
                                                        marginLeft: '40px'
                                                    }}
                                                />
                                            )}
                                            {spacetime === 2 ? (
                                                <Toggle
                                                    text={
                                                        <div>
                                                            <FontAwesomeIcon icon={faThumbtack} /> Closer in space
                                                        </div>
                                                    }
                                                    styles={{}}
                                                />
                                            ) : (
                                                <Toggle
                                                    text={
                                                        <div>
                                                            <FontAwesomeIcon icon={faThumbtack} /> Closer in space
                                                        </div>
                                                    }
                                                    onClick={() => setSpacetime(2)}
                                                    styles={{}}
                                                />
                                            )}
                                            {closeby.map((item, index) => (
                                                <Event
                                                    key={index}
                                                    event={item}
                                                    showDate={true}
                                                    detail={false}
                                                    headline={false}
                                                />
                                            ))}
                                            <Pagination perPage={perPage} total={count} currPage={page} />
                                        </div>
                                    ) : null
                                ) : null}
                            </div>
                        </div>
                    )}
                    <Footer />
                </div>
            }
        />
    );
}

export default EventView;
