/** @jsx jsx */
import { jsx } from 'theme-ui';
import Button from './Button';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type PaginationProps = {
    perPage: number;
    total: number;
    currPage: number;
};

function Pagination({ perPage, total, currPage }: PaginationProps) {
    const queryString = require('query-string');
    const params = queryString.parse(window.location.search);
    let pages = [];
    const last = Math.ceil(total / perPage);
    if (currPage !== 1) {
        pages.push(
            <Button
                key={0}
                text={<FontAwesomeIcon icon={faCaretLeft} />}
                styles={{
                    width: ['50px', '50px', '35px', '35px']
                }}
                onClick={() => {
                    params['page'] = currPage - 1;
                    window.location.search = queryString.stringify(params);
                }}
            />
        );
    }

    for (let i = Math.max(1, currPage - 5); i <= Math.min(last, currPage + 5); i++) {
        if (i === currPage) {
            pages.push(
                <Button
                    key={i}
                    text={i.toString()}
                    styles={{
                        width: ['30px', '35px']
                    }}
                />
            );
        } else {
            pages.push(
                <Button
                    key={i}
                    text={i.toString()}
                    styles={{
                        width: ['30px', '35px']
                    }}
                    onClick={() => {
                        params['page'] = i;
                        window.location.search = queryString.stringify(params);
                    }}
                />
            );
        }
    }

    if (currPage !== last) {
        pages.push(
            <Button
                key={last + 1}
                text={<FontAwesomeIcon icon={faCaretRight} />}
                styles={{
                    width: ['50px', '50px', '35px', '35px']
                }}
                onClick={() => {
                    params['page'] = currPage + 1;
                    window.location.search = queryString.stringify(params);
                }}
            />
        );
    }

    return total > perPage ? (
        <div
            sx={{
                marginLeft: ['auto', 'auto', 'auto', '40px'],
                textAlign: ['center', 'center', 'center', 'left']
            }}
        >
            <div>{pages}</div>
        </div>
    ) : null;
}

export default Pagination;
