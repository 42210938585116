export function Fetch<T>(url: string): Promise<T> {
    const request: RequestInit = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    return fetch(url, request).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<T>;
    });
}

export function DateToUrl(d: Date, exact?: boolean): string {
    const dtf = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        era: 'short',
        timeZone: 'UTC'
    });
    const [{ value: mo }, , { value: da }, , { value: ye }, , { value: er }] = dtf.formatToParts(d);

    let url = '';

    if (er === 'BC') {
        if (exact) {
            url = `/-${ye}/${mo}/${da}`;
        } else {
            url = `/-${ye}/${mo}`;
        }
    } else {
        if (exact) {
            url = `/${ye}/${mo}/${da}`;
        } else {
            url = `/${ye}/${mo}`;
        }
    }

    return url;
}

export function DateToStr(
    year: string,
    month: string,
    day: string,
    exact: boolean
): { dateStr: string; valid: boolean } {
    let dateStr = `${year}/${month}`;
    if (exact) {
        dateStr += `/${day}`;
    } else {
        dateStr += `/01`;
    }
    let valid = false;
    if (Date.parse(dateStr)) {
        valid = true;

        let yearNum = Number(year);
        if (yearNum < 0) {
            yearNum += 1;
        }
        const monthNum = Number(month) - 1;
        let dayNum = 1;
        if (exact) {
            dayNum = Number(day);
        }

        let d = new Date('01/01/2001');
        d.setUTCFullYear(yearNum, monthNum, dayNum);
        const dtf = exact
            ? new Intl.DateTimeFormat('en', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  era: 'short',
                  timeZone: 'UTC',
                  weekday: 'short'
              })
            : new Intl.DateTimeFormat('en', {
                  year: 'numeric',
                  month: 'long',
                  era: 'short',
                  timeZone: 'UTC'
              });

        if (exact) {
            const [
                { value: we },
                ,
                { value: mo },
                ,
                { value: da },
                ,
                { value: ye },
                ,
                { value: er }
            ] = dtf.formatToParts(d);
            if (er === 'BC') {
                dateStr = `${we}, ${mo} ${da}, ${ye} ${er}`;
            } else {
                dateStr = `${we}, ${mo} ${da}, ${ye}`;
            }
        } else {
            const [{ value: mo }, , { value: ye }, , { value: er }] = dtf.formatToParts(d);
            if (er === 'BC') {
                dateStr = `${mo}, ${ye} ${er}`;
            } else {
                dateStr = `${mo}, ${ye}`;
            }
        }
    }

    return { dateStr: dateStr, valid: valid };
}

export function isExact(dateStr: string): boolean {
    let numbers = dateStr.match(/\d+/g)?.map(Number);
    if (numbers && numbers.length > 2) {
        return true;
    } else {
        return false;
    }
}

var h: ReturnType<typeof setTimeout>;

export function debounce<T extends Function>(cb: T, wait = 20) {
    let callable = (...args: any) => {
        clearTimeout(h);
        h = setTimeout(() => cb(...args), wait);
    };
    return (callable as any) as T;
}
