/** @jsx jsx */
import { jsx } from 'theme-ui';
import ContentWithAside from './ContentWithAside';

function Footer() {
    return (
        <ContentWithAside
            styles={{
                marginTop: '100px',
                marginBottom: '20px',
                textAlign: ['center', 'center', 'center', 'left'],
                fontFamily: "'Noto Sans', sans-serif",
                fontSize: '14px'
            }}
            main={
                <div
                    sx={{
                        marginLeft: ['auto', 'auto', 'auto', '40px'],
                        display: ['inline-block', 'inline-block', 'inline-block', 'block']
                    }}
                >
                    <a href="mailto:hi@whataday.info">Contact</a>
                    <a href="/privacy" sx={{ marginLeft: '40px' }}>
                        Privacy Policy
                    </a>
                    <a href="mailto:missing@whataday.info" sx={{ marginLeft: '40px' }}>
                        Report Missing Event
                    </a>
                </div>
            }
            aside={
                <div
                    sx={{
                        marginRight: ['auto', 'auto', 'auto', '40px'],
                        marginBottom: ['10px', '10px', '10px', '0px'],
                        display: ['inline-block', 'inline-block', 'inline-block', 'block']
                    }}
                >
                    <a target="_blank" rel="noopener noreferrer" href="https://nerfsoftware.com">
                        Nerf Software LLC
                    </a>
                    . All rights reserved.
                </div>
            }
        />
    );
}

export default Footer;
