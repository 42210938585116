/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import ContentWithAside from './ContentWithAside';
import '../GoogleFonts.css';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autosuggest from 'react-autosuggest';
import './Header.css';
// import ReactTooltip from 'react-tooltip';
import { DateToUrl, isExact } from '../Common';
import Button from './Button';
import { Fetch, debounce } from '../Common';

type HeaderProps = {
    content: React.ReactNode;
    subtitle?: React.ReactNode;
    showSearch: boolean;
};

class Suggestion {
    search: string;
    date?: number;
    displayDate?: string;
    isExact?: boolean;

    constructor(search: string, date?: number, displayDate?: string, isExact?: boolean) {
        this.search = search;
        this.date = date;
        this.displayDate = displayDate;
        this.isExact = isExact;
    }

    getTitle() {
        if (this.displayDate) {
            if (this.search === '') {
                return this.displayDate;
            } else {
                return this.displayDate + ' | ' + this.search;
            }
        } else {
            return this.search;
        }
    }
}

const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
];

function Header({ content, subtitle, showSearch }: HeaderProps) {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

    function getSuggestions(value: string) {
        let isBC = false;
        if (value.search(/[0-9]{1,5} ?bc\b/) !== -1) {
            isBC = true;
            value = value.replace(/([0-9]{1,5}) ?bc\b/, '$1');
        }

        const terms = value
            .trim()
            .toLowerCase()
            .split(/(\s+)/)
            .filter(e => e.trim().length > 0);

        let list: Suggestion[] = [];

        let found = false;
        for (var numTerms: number = Math.max(3, terms.length); numTerms > 0; numTerms--) {
            for (var startTerm: number = 0; startTerm < terms.length - numTerms + 1; startTerm++) {
                const valid = terms
                    .slice(startTerm, startTerm + numTerms)
                    .every(x => months.some(y => y.startsWith(x)) || !isNaN(Number(x)) || !/^[a-z0-9]+$/i.test(x));
                if (!valid) {
                    continue;
                }

                const dateTerm = terms.slice(startTerm, startTerm + numTerms).join(' ');
                const d = Date.parse(dateTerm);
                if (d) {
                    const dd = new Date(dateTerm);
                    if (isBC) {
                        dd.setUTCFullYear(-dd.getUTCFullYear() + 1);
                    }

                    const searchTerm = terms
                        .slice(0, startTerm)
                        .concat(terms.slice(startTerm + numTerms))
                        .join(' ');

                    const displayOptions = isExact(dateTerm)
                        ? {
                              weekday: 'short',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              timeZone: 'UTC'
                          }
                        : {
                              year: 'numeric',
                              month: 'long',
                              timeZone: 'UTC'
                          };
                    list.push(
                        new Suggestion(
                            searchTerm,
                            dd.getTime() / 1000,
                            dd.toLocaleDateString('en-US', displayOptions) + (isBC ? ' BC' : ''),
                            isExact(dateTerm)
                        )
                    );

                    found = true;
                    break;
                }
            }

            if (found) {
                break;
            }
        }

        const inputValue = value.trim().toLowerCase();
        setSuggestions(list);
        debounce(doFetch, 200)(inputValue, list);
    }

    function doFetch(term: string, list: Suggestion[]) {
        Fetch<{ items: string[] }>('https://api.whataday.info/autocomplete?term=' + encodeURI(term)).then(terms => {
            setSuggestions(list.concat(terms.items.map(item => new Suggestion(item))));
        });
    }

    function shouldRenderSuggestions() {
        return true;
    }

    function gotoTerm(term: string) {
        window.location.href = '/search?q=' + encodeURI(term);
    }

    function gotoSuggestion(suggestion: Suggestion) {
        let url = '';

        if (suggestion.date) {
            let d = new Date(0);
            d.setUTCSeconds(suggestion.date);
            if (d !== null) {
                url = DateToUrl(d, suggestion.isExact);
            } else {
                url = '/search';
            }
        } else {
            url = '/search';
        }

        if (suggestion.search) {
            url += '?q=' + encodeURI(suggestion.search);
        }

        window.location.href = url;
    }

    return (
        <div>
            <ContentWithAside
                styles={{
                    marginTop: ['0px', '0px', '0px', '100px'],
                    marginBottom: '50px'
                }}
                main={
                    <div>
                        <div
                            sx={{
                                marginLeft: ['20px', '40px', '40px', '40px'],
                                fontSize: ['40px', '64px'],
                                fontFamily: "'Anton', sans-serif"
                            }}
                        >
                            {content}
                        </div>
                        {subtitle ? (
                            <div
                                sx={{
                                    marginLeft: ['20px', '40px', '40px', '40px'],
                                    fontSize: ['16px', '24px'],
                                    fontFamily: "'Oswald', sans-serif"
                                }}
                            >
                                {subtitle}
                            </div>
                        ) : null}
                    </div>
                }
                aside={
                    <div
                        sx={{
                            paddingTop: ['50px', '100px', '100px', '0px'],
                            paddingLeft: ['20px', '40px', '40px', '40px'],
                            paddingRight: ['20px', '40px', '40px', '40px'],
                            textAlign: ['left', 'left', 'left'],
                            backgroundColor: 'var(--color-blue)',
                            color: 'var(--color-white)'
                        }}
                    >
                        <div
                            sx={{
                                fontSize: ['8vw', '64px'],
                                fontFamily: "'Anton', sans-serif",
                                letterSpacing: '2px',
                                fontWeight: 'normal',
                                width: ['100%', null, null, null]
                            }}
                        >
                            <a href="/">whataday</a>
                        </div>
                        {/*
                        <div
                            sx={{
                                fontFamily: "'Oswald', sans-serif",
                                fontWeight: 'normal',
                                fontSize: ['14px', '16px']
                            }}
                        >
                            Explorer events in space-time{' '}
                            <span
                                sx={{
                                    cursor: 'pointer'
                                }}
                                data-tip="Lookup what happened, when, and where. <br/>Browse what happened closeby, in both time and space."
                                data-event="click focus"
                            >
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </span>
                            <ReactTooltip
                                place="top"
                                type="light"
                                effect="solid"
                                globalEventOff="click"
                                multiline={true}
                                sx={{
                                    opacity: '1 !important'
                                }}
                            />
                        </div>
                            */}
                    </div>
                }
            />
            {showSearch ? (
                <ContentWithAside
                    styles={{
                        marginBottom: ['20px', '20px', '20px', '150px'],
                        fontSize: ['16px', '24px', '24px', '24px']
                    }}
                    main={
                        <div
                            sx={{
                                marginLeft: ['20px', '20px', '20px', '5px'],
                                marginRight: '20px'
                            }}
                        >
                            <form
                                onSubmit={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    gotoTerm(value);
                                }}
                                sx={{
                                    display: 'flex'
                                }}
                            >
                                <Autosuggest
                                    suggestions={suggestions}
                                    shouldRenderSuggestions={shouldRenderSuggestions}
                                    onSuggestionsFetchRequested={({ value }) => {
                                        debounce(getSuggestions, 200)(value);
                                    }}
                                    onSuggestionsClearRequested={() => {
                                        setSuggestions([]);
                                    }}
                                    getSuggestionValue={suggestion => suggestion.getTitle()}
                                    renderSuggestion={(suggestion, { query, isHighlighted }) => {
                                        let classString = 'suggestion-item';
                                        if (isHighlighted) {
                                            classString += ' suggestion-item-highlighted';
                                        }

                                        return <div className={classString}>{suggestion.getTitle()}</div>;
                                    }}
                                    inputProps={{
                                        placeholder: 'Search...',
                                        type: 'text',
                                        value: value,
                                        onChange: (_, { newValue, method }) => {
                                            setValue(newValue);
                                        }
                                    }}
                                    renderSuggestionsContainer={({ containerProps, children, query }) => {
                                        if (children) {
                                            return (
                                                <div {...containerProps}>
                                                    {query.length > 0 ? (
                                                        <div className="suggestion-item-hint">
                                                            Press Enter to search <strong>{query}</strong>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="suggestion-item-hint">
                                                                Try searching for event, location, date, person, ...
                                                            </div>
                                                            <div className="suggestion-item-hint">
                                                                Hint: type "year/month/day" (e.g. "2001/09/11") to
                                                                search for that date
                                                            </div>
                                                        </div>
                                                    )}
                                                    {children}
                                                </div>
                                            );
                                        } else {
                                            return <div {...containerProps} />;
                                        }
                                    }}
                                    onSuggestionSelected={(
                                        event,
                                        { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
                                    ) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        gotoSuggestion(suggestion);
                                    }}
                                />
                                <Button
                                    text={'GO'}
                                    onClick={() => gotoTerm(value)}
                                    styles={{
                                        marginTop: '0px',
                                        marginBottom: '0px'
                                    }}
                                />
                            </form>
                        </div>
                    }
                    aside={
                        <div
                            sx={{
                                fontFamily: "'Oswald', sans-serif",
                                marginRight: '10px',
                                display: ['none', 'none', 'none', 'block']
                            }}
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                    }
                />
            ) : null}
        </div>
    );
}

export default Header;
