/** @jsx jsx */
import { jsx } from 'theme-ui';

type SkeletonProps = {
    repeat: number;
    styles: object;
    globalStyles: object;
};

export function Skeleton({ repeat, styles, globalStyles }: SkeletonProps) {
    return (
        <div
            sx={{
                clear: 'both',
                ...globalStyles
            }}
        >
            {Array.apply(null, Array(repeat)).map((item, index) => (
                <div
                    key={index}
                    sx={{
                        margin: '20px',
                        minWidth: ['40px', '100px', '100px', '100px'],
                        ...styles
                    }}
                ></div>
            ))}
        </div>
    );
}
