import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/browser';

Sentry.init({dsn: "https://c85ba72a407a41cbbc0801ec499cd7e8@o399199.ingest.sentry.io/5258984"});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
