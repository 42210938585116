/** @jsx jsx */
import { jsx } from 'theme-ui';

type ContainerProps = {
    content: React.ReactNode;
};

function Container({ content }: ContainerProps) {
    return (
        <div
            sx={{
                display: 'flex',
                width: 'auto'
            }}
        >
            <aside
                sx={{
                    width: ['0%', '0%', '0%', '30%'],
                    margin: '0px',
                    minHeight: '100vh',
                    backgroundColor: 'var(--color-blue)',
                    color: 'var(--color-white)'
                }}
            />
            <div
                className="content"
                sx={{
                    width: ['100%', '100%', '100%', '70%'],
                    color: 'var(--color-black)',
                    backgroundColor: 'var(--color-white)'
                }}
            >
                {content}
            </div>
        </div>
    );
}

export default Container;
