/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState, useEffect } from 'react';

import Container from '../components/Container';
import Header from '../components/Header';
import { EventItem, Event } from '../components/Event';
import Separator from '../components/Separator';
import Button from '../components/Button';
import Footer from '../components/Footer';
import { Fetch } from '../Common';
import '../Main.css';

function HomeView() {
    const [events, setEvents] = useState<(EventItem | undefined)[]>(new Array(10).fill(undefined));
    const [error, setError] = useState<boolean>(false);

    function load() {
        setEvents(new Array(10).fill(undefined));
        Fetch<EventItem[]>('https://api.whataday.info/featured?limit=10')
            .then(events => {
                setEvents(events);
            })
            .catch(() => setError(true));
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <Container
            content={
                <div>
                    <Header
                        content={
                            <span
                                sx={{
                                    letterSpacing: '1px'
                                }}
                            >
                                EVERYDAY IS EVENTFUL
                            </span>
                        }
                        subtitle={'Search and explore events, in space-time'}
                        showSearch={true}
                    />
                    {error ? (
                        <Separator content={'Error loading page'} />
                    ) : (
                        <div>
                            <Separator content={'Featured Events'} />
                            <Button
                                text="Load Random"
                                onClick={() => load()}
                                styles={{
                                    marginLeft: ['20px', '40px', '40px', '40px']
                                }}
                            />
                            {events.map((item, index) => (
                                <Event key={index} event={item} showDate={true} detail={false} headline={false} />
                            ))}
                        </div>
                    )}
                    <Footer />
                </div>
            }
        />
    );
}

export default HomeView;
