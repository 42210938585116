/** @jsx jsx */
import { jsx } from 'theme-ui';

type ToggleProps = {
    text: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    styles: object;
};

function Toggle({ text, onClick, styles }: ToggleProps) {
    return onClick ? (
        <button
            type="button"
            sx={{
                fontSize: ['16px', '24px'],
                fontFamily: "'Oswald', sans-serif",
                margin: '5px',
                color: 'var(--color-black)',
                background: 'var(--color-white)',
                border: '0 none',
                cursor: 'pointer',
                borderRadius: '2px',
                ':hover': {
                    color: 'var(--color-white)',
                    background: 'var(--color-blue)'
                },
                ':focus': {
                    outline: '0'
                },
                ...styles
            }}
            onClick={onClick}
        >
            {text}
        </button>
    ) : (
        <button
            type="button"
            sx={{
                fontSize: ['16px', '24px'],
                fontFamily: "'Oswald', sans-serif",
                margin: '5px',
                color: 'var(--color-white)',
                background: 'var(--color-blue)',
                border: '0 none',
                cursor: 'default',
                borderRadius: '2px',
                ':focus': {
                    outline: '0'
                },
                ...styles
            }}
        >
            {text}
        </button>
    );
}

export default Toggle;
